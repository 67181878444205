<template>
  <section>
    <content-section :spacing="false">
      <div class=" py-5 ">
        <div class="flex justify-center  ">
          <div class="relative">
            <div
              v-if="isLiveStreaming"
              class="flex items-center absolute bottom-2 left-2 z-10"
            >
              <div class="flex relative w-4 h-4">
                <span
                  class="inline-flex absolute w-full h-full bg-red-500 rounded-full opacity-75 animate-ping"
                />
                <span
                  class="inline-flex relative mt-1 ml-1 w-2 h-2 rounded-full bg-oRed"
                />
              </div>

              <div class="bg-oRed px-2  text-oWhite text-sm rounded-lg">
                Live
              </div>
            </div>

            <div
              class="video-wrapper flex justify-center items-center text-2xl text-gray-400"
              :class="{ 'border border-gray-200 rounded-md': !isLiveStreaming }"
              ref="videoCamera"
            >
              Please click <span class="mx-1 text-gray-600">Play</span> button
            </div>
          </div>
        </div>
        <div class="flex justify-center gap-2 py-3">
          <AppButton
            variant="primary"
            height="30px"
            :is-loading="playLoading"
            @click="play"
          >
            Play
          </AppButton>
          <AppButton
            variant="orange"
            height="30px"
            :is-loading="stopLoading"
            @click="stop"
          >
            Stop
          </AppButton>
        </div>
      </div>
    </content-section>
  </section>
</template>

<script>
import JSMpeg from '@cycjimmy/jsmpeg-player'
import axios from 'axios'
import { SunpodsConfig } from '@/config/SunpodsConfig'
export default {
  name: 'ViewSunpodCamera',
  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
  },
  data() {
    return {
      player: null,
      isLiveStreaming: false,
      isCameraConfigLoading: false,
      playLoading: false,
      stopLoading: false,
      sunpod_camera_ip: null,
      sunpod_camera_rtsp_user: null,
      sunpod_camera_rtsp_password: null,
    }
  },

  destroyed() {
    if (this.isLiveStreaming) {
      this.stop()
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload, {
      capture: true,
    })
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },
  created() {
    this.isCameraConfigLoading = true
    this.$http
      .get(SunpodsConfig.api.detail(this.id))
      .then((res) => {
        this.sunpod_camera_ip = res.data.sunpod_details.sunpod_camera_ip
        this.sunpod_camera_rtsp_user =
          res.data.sunpod_details.sunpod_camera_rtsp_user
        this.sunpod_camera_rtsp_password =
          res.data.sunpod_details.sunpod_camera_rtsp_password
      })
      .finally(() => {
        this.isCameraConfigLoading = false
      })
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },

  methods: {
    handleBeforeUnload(event) {
      if (this.isLiveStreaming) {
        const msg = 'Do you want to leave?'
        event.returnValue = msg

        return msg
      }
    },
    play() {
      if (this.isCameraConfigLoading) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: 'Info',
            text: 'Please wait for loading camera configuration',
          },
          4000
        )
        return
      }

      if (
        !this.isCameraConfigLoading &&
        (!this.sunpod_camera_ip ||
          !this.sunpod_camera_rtsp_user ||
          !this.sunpod_camera_rtsp_password)
      ) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: 'Info',
            text: 'Missing camera configuration, please check sunpod details.',
          },
          4000
        )
        return
      }

      if (this.isLiveStreaming) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: 'Info',
            text: 'Video is already streaming.',
          },
          4000
        )
        return
      }

      this.playLoading = true
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_IP_CAMERA_BASE_ADDRESS}start-stream`,
        data: {
          rtspUrl: `rtsp://${this.sunpod_camera_rtsp_user}:${this.sunpod_camera_rtsp_password}@${this.sunpod_camera_ip}`,
        },
      })
        .then((res) => {
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: `${res.data.message}. Please wait.`,
            },
            3000
          )
          setTimeout(() => {
            this.player = new JSMpeg.VideoElement(
              this.$refs['videoCamera'],
              `${process.env.VUE_APP_IP_CAMERA_WS_ADDRESS}`
            )
            this.isLiveStreaming = true
            this.player.play()
          }, 2000)
        })
        .catch((err) => {
          console.log('camera-stream-err', { err })
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Failed',
              text: err.response
                ? err.response.data.error
                : `Failed to start streaming`,
            },
            5000
          )
        })
        .finally(() => (this.playLoading = false))
    },
    stop() {
      if (this.isCameraConfigLoading) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: 'Info',
            text: 'Please wait for loading camera configuration',
          },
          4000
        )
        return
      }

      if (
        !this.isCameraConfigLoading &&
        (!this.sunpod_camera_ip ||
          !this.sunpod_camera_rtsp_user ||
          !this.sunpod_camera_rtsp_password)
      ) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: 'Info',
            text: 'Missing camera configuration, please check sunpod details.',
          },
          4000
        )
        return
      }
      this.stopLoading = true
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_IP_CAMERA_BASE_ADDRESS}stop-stream`,
      })
        .then(() => {
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: `Streaming has been stopped`,
            },
            3000
          )
          this.isLiveStreaming = false
          if (this.player) {
            this.player.destroy()
          }

          this.player = null
        })
        .catch((err) => {
          console.log('camera-stream-err', { err })
          if (err.response.data.error === 'No active stream to stop') {
            this.$notify(
              {
                group: 'generic',
                type: 'default',
                title: 'Info',
                text: err.response.data.error,
              },
              5000
            )
            this.isLiveStreaming = false
            this.player.destroy()
            this.player = null
          } else {
            this.$notify(
              {
                group: 'bottomRight',
                type: 'error',
                title: 'Failed',
                text: err.response
                  ? err.response.data.error
                  : `Failed to start streaming`,
              },
              5000
            )
          }
        })
        .finally(() => (this.stopLoading = false))
    },
  },
}
</script>

<style lang="scss">
.video-wrapper {
  width: 50rem;
  height: 28rem;
  background: aliceblue;
}
</style>
