export const SunpodsConfig = {
  api: {
    // get
    index: '/dashboard/sunpods/',
    // post
    create: '/dashboard/sunpods/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/sunpods/${id}/`
    },
    //get
    detail: (id = 'uuid') => {
      return `/dashboard/sunpods/${id}/`
    },
    //post
    assignedEdgebox: (id = 'uuid') => {
      return `/dashboard/sunpods/${id}/add-edgebox/`
    },
    //post
    addVehicle: (id = 'uuid') => {
      return `/dashboard/sunpods/${id}/add-vehicle/`
    },
    //get
    doorStatus: (id = 'uuid') => {
      return `/dashboard/sunpods/${id}/door-status/`
    },
    //patch
    openDoor: (id = 'uuid') => {
      return `/dashboard/sunpods/${id}/open/`
    },
    //patch
    closeDoor: (id = 'uuid') => {
      return `/dashboard/sunpods/${id}/close/`
    },
    //logs
    logs: (id = 'uuid') => {
      return `/dashboard/request-logs/sun-pods/${id}/`
    },
    //get
    assignableVehicles: (id = 'uuid') => {
      return `/dashboard/sunpods/${id}/assignable-vehicles/`
    },
  },
  events: {
    name: 'sunpods',
    // refresh-index-data
    refresh: `rid-sunpods`,
    // slideover-right
    sorId: 'sunpods',
    sorOpen: 'sor-open-sunpods',
    sorClose: 'sor-close-sunpods',
    sorToggle: 'sor-toggle-sunpods',
    // editing-data
    editingData: 'edit-sunpods-data',
    // viewing-data
    viewingData: 'view-sunpods-data',
  },
}
